import * as turf from "@turf/convex";
import L, { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import { useEffect, useState } from "react";
import { GeoJSON } from "react-leaflet";

const getPlantStatus = (features) => {
  if (features.tags?.["power"] === "plant") {
    return "Operating";
  } else if (features.tags?.["construction:power"] === "plant") {
    return "Under Construction";
  } else {
    return "Unknown/Retired";
  }
};
// Define custom icons for each fuel type
const getFuelIcon = (fuelType) => {
  let iconUrl;

  switch (fuelType) {
    case "coal":
      iconUrl = "https://cdn-icons-png.flaticon.com/512/3327/3327023.png";
      break;
    case "solar":
    case "solar;battery":
      // iconUrl = "https://cdn-icons-png.flaticon.com/512/3198/3198167.png";
      iconUrl = "https://cdn-icons-png.flaticon.com/512/2511/2511664.png";
      break;
    case "wind":
      iconUrl = "https://cdn-icons-png.flaticon.com/512/9367/9367346.png";
      break;
    case "gas":
    case "gas;diesel":
    case "diesel;gas":
    case "abandoned_mine_methane":
    case "gas;oil":
    case "coalbed_methane":
      iconUrl = "https://cdn-icons-png.flaticon.com/512/4535/4535728.png";
      break;
    case "hydro":
      iconUrl = "https://cdn-icons-png.flaticon.com/512/1946/1946138.png";
      break;
    case "biogas":
      iconUrl = "https://cdn-icons-png.flaticon.com/512/5451/5451263.png";
      break;
    case "biofuel":
    case "biomass":
      iconUrl = "https://cdn-icons-png.flaticon.com/512/11049/11049767.png";
      break;
    case "diesel":
    case "oil":
      iconUrl = "https://cdn-icons-png.flaticon.com/512/4515/4515613.png";
      break;
    case "battery":
      iconUrl = "https://cdn-icons-png.flaticon.com/512/3463/3463455.png";
      break;
    default:
      iconUrl = "https://cdn-icons-png.flaticon.com/512/3540/3540110.png";
  }

  return new Icon({
    iconUrl,
    iconSize: [25, 25],
    iconAnchor: [12, 12],
    popupAnchor: [0, -10],
  });
};

const calculateHull = (points) => {
  if (points.length < 3) {
    // Convex hull requires at least 3 points
    return null;
  }

  // Create a GeoJSON FeatureCollection of the points
  const pointsGeoJSON = {
    type: "FeatureCollection",
    features: points.map(([lon, lat]) => ({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [lon, lat],
      },
    })),
  };

  // Calculate the convex hull using @turf/convex
  const hull = turf.convex(pointsGeoJSON);

  return hull || null;
};

const PowerStations = () => {
  const [powerStations, setPowerStations] = useState();
  const [listStations, setListStations] = useState();

  const fetchPowerPlants = async () => {
    const outerOverpassQuery = `
      [out:json][timeout:50000];
      area[name="Australia"]->.a;
      (
        relation["power"="plant"](area.a);
        relation["construction:power"="plant"](area.a);
      )->.plants;
      (
        .plants->.outer_ways;
        way(r.outer_ways)["role"="outer"];
        node(w.outer_ways);
      );
      out body geom;
    `;
    const normalOverpassQuery = `
      [out:json][timeout:50000];
      area[name="Australia"]->.a;
      (
        node["power"="plant"](area.a);
        node["construction:power"="plant"](area.a);
        way["power"="plant"](area.a);
        way["construction:power"="plant"](area.a);
      );
      out body;
      >;
      out geom;
    `;

    const outerResponse = await fetch(
      "https://overpass-api.de/api/interpreter",
      {
        method: "POST",
        body: new URLSearchParams({ data: outerOverpassQuery }),
      }
    );

    const normalResponse = await fetch(
      "https://overpass-api.de/api/interpreter",
      {
        method: "POST",
        body: new URLSearchParams({ data: normalOverpassQuery }),
      }
    );

    const outerData = await outerResponse.json();
    const normalData = await normalResponse.json();

    const geoJsonData = {
      type: "FeatureCollection",
      features: processPowerStations(outerData.elements, normalData.elements),
    };

    return geoJsonData;
  };

  const processPowerStations = (outerElements, normalElements) => {
    const processedStations = [];
    const powerStationNames = [];

    // Add the polygon feature if hull exists for outerElements
    outerElements.forEach((relation) => {
      const points = [];

      // Extract points from members
      relation.members.forEach((member) => {
        if (member.type === "node") {
          if (member.lon !== NaN && member.lat !== NaN) {
            points.push([member.lon, member.lat]);
          }
        } else if (member.type === "way") {
          // Calculate the centroid for ways and relations
          if (member.geometry) {
            member.geometry.forEach((node) => {
              if (node.lon !== NaN && node.lat !== NaN) {
                points.push([node.lon, node.lat]);
              }
            });
          }
        }
      });

      const hull = calculateHull(points);
      // Check if the name already exists in the list
      const alreadyExists = processedStations.some((station) => {
        return station.properties.name === relation.tags.name;
      });

      // Find the index of the object with the same name
      const index = processedStations.findIndex(
        (station) => station.properties.name === relation.tags.name
      );

      // Calculate Centroid
      if (points.length >= 3 && !alreadyExists) {
        const lonSum = points.reduce((sum, [lon]) => sum + lon, 0);
        const latSum = points.reduce((sum, [, lat]) => sum + lat, 0);
        const centroid = [lonSum / points.length, latSum / points.length];

        const newPowerStationFeature = {
          type: "Feature",
          properties: {
            id: relation.id,
            ...relation.tags,
            plantSource: relation.tags?.["plant:source"] || "",
            plantStatus: getPlantStatus(relation) || "",
            isCentroid: true,
          },
          geometry: {
            type: "Point",
            coordinates: centroid,
          },
        };

        if (index !== -1) {
          // If the object exists, replace it
          processedStations[index] = newPowerStationFeature;
        } else {
          // If the object doesn't exist, push it
          processedStations.push(newPowerStationFeature);
        }

        // Extract all name fields into a list
        // powerStationNames.push({
        //   name: relation.tags.name,
        //   coordinates: centroid,
        // });
      }

      if (hull && !alreadyExists) {
        const newPowerStationFeature = {
          type: "Feature",
          properties: {
            id: relation.id,
            ...relation.tags,
            plantStatus: getPlantStatus(relation) || "",
          },
          geometry: hull.geometry,
        };

        if (index !== -1) {
          // If the object exists, replace it
          processedStations[index] = newPowerStationFeature;
        } else {
          // If the object doesn't exist, push it
          processedStations.push(newPowerStationFeature);
        }
      }
    });

    // add normal power stations
    normalElements.map((element) => {
      if (element.type === "node") {
      } else if (
        element.type === "way" ||
        element.type === "relation" ||
        element.type === "multipolygon"
      ) {
        // Calculate the centroid for ways and relations
        if (element.nodes) {
          const nodes = element.nodes
            .map((nodeId) =>
              normalElements.find(
                (el) => el.id === nodeId && el.type === "node"
              )
            )
            .filter(Boolean);

          if (nodes.length > 0) {
            const lonSum = nodes.reduce((sum, node) => sum + node.lon, 0);
            const latSum = nodes.reduce((sum, node) => sum + node.lat, 0);
            const centroid = [
              lonSum / nodes.length, // Average longitude
              latSum / nodes.length, // Average latitude
            ];
            // Add a point feature for the centroid
            if (
              !processedStations.some(
                (feature) => feature.properties.name === element.tags?.name
              )
            ) {
              processedStations.push({
                type: "Feature",
                properties: {
                  id: element.id,
                  ...element.tags,
                  plantSource:
                    element.tags && element.tags["plant:source"]
                      ? element.tags["plant:source"]
                      : "", // Include plant source for icon
                  plantStatus: getPlantStatus(element) || "",
                },

                geometry: {
                  type: "Point",
                  coordinates: centroid,
                },
              });

              // Extract all name fields into a list
              // powerStationNames.push({
              //   name: element.tags.name,
              //   coordinates: centroid,
              // });
            }
          }
        }
      }
    });

    setListStations(powerStationNames);

    return processedStations;
  };

  useEffect(() => {
    const fetchData = async () => {
      const geoJsonData = await fetchPowerPlants();
      setPowerStations(geoJsonData);
    };

    fetchData();
  }, []);

  // useEffect(() => {
  //   if (listStations?.length > 0) {
  //     // Convert the object to a JS module
  //     // Write to a .js file
  //     localStorage.setItem("myData", `${JSON.stringify(listStations)}`);

  //     console.log(`${JSON.stringify(listStations)}`);
  //   }
  // }, [listStations]);

  return (
    <>
      {powerStations && (
        <GeoJSON
          data={powerStations}
          pointToLayer={(feature, latlng) => {
            if (feature.geometry.type === "Point") {
              // Use the fuel type to get the appropriate icon
              const fuelType = feature.properties.plantSource || "Unknown";
              if (fuelType === "Unknown") {
                return L.marker(latlng, { icon: getFuelIcon(fuelType) });
              } else {
                return L.marker(latlng, { icon: getFuelIcon(fuelType) });
              }
            }
          }}
          style={(feature) => {
            if (feature.geometry.type === "Polygon") {
              const status = feature.properties.plantStatus || "";
              if (status === "Operating") {
                return {
                  color: "green", // Border color
                  weight: 2, // Border width
                  opacity: 0.2,
                  fillOpacity: 0.2, // Transparency inside the polygon
                };
              } else {
                return {
                  color: "grey", // Border color
                  weight: 2, // Border width
                  opacity: 0.2,
                  fillOpacity: 0.2, // Transparency inside the polygon
                };
              }
            }
            return {};
          }}
          onEachFeature={(feature, layer) => {
            // Bind a popup to display the plant details
            layer.bindPopup(`
              <div>
                <strong>Power Plant Name:</strong> ${
                  feature.properties.name || ""
                } <br/>
                <strong>Operator:</strong> ${
                  feature.properties.operator || ""
                } <br/>
                <strong>Power:</strong> ${
                  feature.properties["plant:output:electricity"] || ""
                } <br/>
                <strong>Fuel Type:</strong> ${
                  feature.properties.plantSource || "Unknown"
                } <br/>
                <strong>Start Date:</strong> ${
                  feature.properties.start_date || ""
                } <br/>
                <strong>Status:</strong> ${
                  feature.properties.plantStatus || ""
                }<br/>
                 <strong>Reference</strong> ${
                   feature.properties.ref || ""
                 } <br/>
              </div>
            `);

            // Add a click event listener to log properties when a line is clicked
            layer.on("click", () => {
              console.log("Clicked feature properties:", feature.properties);
            });
          }}
        />
      )}
    </>
  );
};

export default PowerStations;
