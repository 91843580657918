import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Box, IconButton, Popover, Typography } from "@mui/material";
import { useState } from "react";

export default function GanttChartLegendNOS() {
  const [anchorElLegend, setAnchorElLegend] = useState(null);
  const [anchorElStatus, setAnchorElStatus] = useState(null);
  const handleClickStatus = (event) => {
    setAnchorElStatus(event.currentTarget);
  };

  const handleCloseStatus = () => {
    setAnchorElStatus(null);
  };
  const colorDict = {
    SUBMIT: "rgba(255, 131, 43, 0.5)", // Orange
    MTLTP: "rgba(241, 194, 27, 0.5)", // Yellow
    STLTP: "rgba(241, 194, 27, 0.5)", // Yellow
    PDLTP: "rgba(241, 194, 27, 0.5)", // Yellow
    PTP: "rgba(0, 128, 0, 0.5)", // Green
    PTR: "rgba(0, 0, 255, 0.5)", // Blue
    RESUBMIT: "rgba(255, 131, 43, 0.5)", // Orange
    UTP: "rgba(218, 30, 40, 0.5)", // Red
    "WD REQ": "rgba(218, 30, 40, 0.5)", //Red
    WDRAWN: "rgba(128, 128, 128, 0.5)", //Grey
    COMPLETE: "rgba(128, 128, 128, 0.5)", //Grey
    INFO: "rgba(128, 128, 128, 0.5)", //Grey
  };

  const openLegend = Boolean(anchorElLegend);
  const openStatus = Boolean(anchorElStatus);

  return (
    <>
      <Box display="flex" justifyContent="right">
        {/* Outage Status Icon and Popup */}
        <Box display="flex" alignItems="center">
          <IconButton
            aria-label="outage-status"
            aria-describedby={openStatus ? "status-popover" : undefined}
            onClick={handleClickStatus}
          >
            <HelpOutlineIcon />
          </IconButton>
          <Typography variant="body1" fontStyle="italic">
            Status Hint
          </Typography>
        </Box>
      </Box>

      <Popover
        id="status-popover"
        open={openStatus}
        anchorEl={anchorElStatus}
        onClose={handleCloseStatus}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box p={2} maxWidth={400}>
          <Typography variant="h6" fontWeight="bold" gutterBottom>
            Outage Status Types
          </Typography>
          <Typography variant="body2" paragraph>
            This indicates the likelihood of the outage proceeding. There are
            various planning stages involved with outages. At each stage, the
            system conditions are assessed with respect to the forecast demand
            and the state of the power system. Unplanned events can arise that
            alter the likelihood of a planned outage proceeding. The status of
            an outage may be:
          </Typography>

          {/* Function to generate the colored box and text */}
          {[
            {
              code: "SUBMIT",
              description:
                "The outage request has been submitted by the TNSP to AEMO for assessment.",
            },
            {
              code: "RESUBMIT",
              description:
                "The outage booking has been updated by the TNSP. The outage requires assessment by AEMO.",
            },
            {
              code: "MTLTP",
              description:
                "Medium Term Likely to Proceed. The outage has been assessed in the medium term by AEMO and is likely to proceed.",
            },
            {
              code: "STLTP",
              description:
                "Short Term Likely to Proceed. The outage has been assessed in the short term by AEMO and is likely to proceed.",
            },
            {
              code: "PDLTP",
              description:
                "Pre-Dispatch Likely to Proceed. The outage has been assessed in pre-dispatch by AEMO and is likely to proceed.",
            },
            {
              code: "PTP",
              description:
                "Permission to Proceed. The outage has been given permission by AEMO to begin.",
            },
            {
              code: "PTR",
              description:
                "Permission to Restore. The outage has been given permission by AEMO for restoration to begin.",
            },
            {
              code: "UTP",
              description:
                "The Issue could not be resolved at the time of assessment and the outage is unlikely to proceed.",
            },
            {
              code: "WD REQ",
              description: "Withdraw Request.",
            },
            {
              code: "WDRAWN",
              description: "Withdrawn.",
            },
            {
              code: "COMPLETE",
              description: "Completed.",
            },
            {
              code: "INFO",
              description: "Information.",
            },
          ].map((status) => (
            <Typography variant="body2" paragraph key={status.code}>
              <Box
                component="span"
                sx={{
                  display: "inline-block",
                  width: 16,
                  height: 16,
                  backgroundColor: colorDict[status.code], // Ensure colorDict includes colors for the new statuses
                  marginRight: 1,
                  verticalAlign: "middle",
                  borderRadius: "3px", // Optional: Add border-radius for rounded corners
                }}
              />
              <strong>{status.code}</strong> - {status.description}
            </Typography>
          ))}
        </Box>
      </Popover>
    </>
  );
}
