import {
  Box,
  Button,
  CssBaseline,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import { Fragment, useContext } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/section-headers/Header";
import TextLink from "../../components/section-headers/TextLink";
import AuthContext from "../../context/AuthContext";
import { ColorModeContext } from "../../theme";
import SiteMarkIconLegal from "./SiteMarkIconLegal";

const Activate = () => {
  let { verifyUser } = useContext(AuthContext);
  let { uid, token } = useParams();
  const theme = useTheme();

  const callVerifyUser = async (uid, token) => {
    await verifyUser(uid, token);
  };
  // Redirect could confuse user and lead to receiving error messagez
  // useEffect(() => {
  //   if (uid.length !== 0) {
  //     callVerifyUser(uid, token);
  //   } else {
  //     // nothing
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [uid, token]);

  return (
    <ColorModeContext.Provider value="dark">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Fragment>
          <Box m="20px">
            <SiteMarkIconLegal />
            {/* <Title title="Welcome to this New System!" subtitle="This platform provides insight into the decisions and operations." /> */}
            <Fragment>
              <Box px={{ xs: 1, sm: 2, md: 30, lg: 50, xl: 75 }}>
                <Header
                  title="Verify your Account"
                  subtitle="Please confirm your email address by clicking the Activate button below."
                />

                <Box display="flex" justifyContent="end" mt="20px">
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    onClick={() => callVerifyUser(uid, token)}
                  >
                    Activate
                  </Button>
                </Box>

                <TextLink
                  text="Don't have an account?"
                  link_text="Sign Up"
                  link="/signup"
                />
              </Box>
            </Fragment>
          </Box>
        </Fragment>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default Activate;
