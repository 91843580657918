import { Box, useTheme } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { tokens } from "../../theme";

const NOSOutagesAllTable = ({ constraints }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const columns = [
    { field: "outageid", headerName: "OUTAGE ID", minWidth: 75, flex: 0.75 },
    {
      field: "substationid",
      headerName: "SUBSTATION ID",
      minWidth: 100,
      flex: 0.75,
    },
    {
      field: "genconsetid",
      headerName: "CONSTRAINT SET",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "substationdescription",
      headerName: "SUBSTATION DESCRIPTION",
      flex: 1,
    },
    {
      field: "regionid",
      headerName: "REGION",
      flex: 0.5,
    },
    {
      field: "ownerid",
      headerName: "OWNER",
      flex: 0.5,
    },
    {
      field: "voltage",
      headerName: "VOLTAGE",
      flex: 0.5,
    },
    {
      field: "equipmentdescription",
      headerName: "EQUIPMENT DESCRIPTION",
      flex: 1,
    },
    {
      field: "equipmentid",
      headerName: "EQUIPMENT ID",
      minWidth: 75,
      flex: 0.75,
    },
    { field: "equipmenttype", headerName: "TYPE", minWidth: 75, flex: 0.5 },
    {
      field: "outagestatuscode",
      headerName: "STATUS",
      minWidth: 75,
      flex: 0.5,
    },
    { field: "starttime", headerName: "START TIME", minWidth: 120, flex: 1 },
    { field: "endtime", headerName: "END TIME", minWidth: 120, flex: 1 },
    { field: "reason", headerName: "REASON", minWidth: 120, flex: 1 },
    {
      field: "resubmitreason",
      headerName: "RESUBMIT REASON",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "lastchanged",
      headerName: "LAST CHANGED",
      minWidth: 120,
      flex: 1,
    },
  ];

  const firstRowAggregation = {
    apply: ({ values }) => (values.length > 0 ? values[0] : null),
    columnTypes: ["string", "number", "date", "dateTime"],
  };

  return (
    <Box
      sx={{
        width: "100%",
        "& .name-column--cell": {
          color: colors.grey[100],
        },
        "& .MuiDataGrid-columnHeader": {
          backgroundColor: colors.tableheader,
          borderBottom: "none",
          fontSize: "12px",
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: colors.grey[200],
        },
        "& .MuiDataGrid-cell": {
          fontFamily: "Source Sans Pro, sans-serif", // Font for cell text
          fontSize: "12px",
        },
      }}
    >
      <DataGridPremium
        rows={constraints}
        columns={columns}
        pagination
        pageSize={200}
        pageSizeOptions={[100, 200, 300, 400, 500]} // Limit selectable page sizes to 200
        density={"compact"}
        rowGroupingColumnMode="multiple"
        aggregationFunctions={{
          firstRow: firstRowAggregation,
        }}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        initialState={{
          pageSize: 200,
          rowGrouping: {
            model: ["outageid"],
          },
          aggregation: {
            model: {
              genconsetid: "firstRow",
              starttime: "firstRow", // Or 'min' if you prefer the earliest time
              endtime: "firstRow", // Or 'max' if you prefer the latest time
              substationid: "firstRow",
              equipmentid: "firstRow",
              equipmenttype: "firstRow",
              outagestatuscode: "firstRow",
              reason: "firstRow",
              resubmitreason: "firstRow",
              lastchanged: "firstRow",
              substationdescription: "firstRow",
              regionid: "firstRow",
              ownerid: "firstRow",
              voltage: "firstRow",
              equipmentdescription: "firstRow",

              // Include other columns as needed
            },
          },
          groupingExpansion: { "*": true }, // Expand all groups
          columns: {
            columnVisibilityModel: {
              outageid: false,
              substationid: false,
              equipmentid: false,
              description: false,
              lastchanged: false,
              reason: false,
              resubmitreason: false,
            },
          },
        }}
      />
    </Box>
  );
};

export default NOSOutagesAllTable;
