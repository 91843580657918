import { Box, Chip, useTheme } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { tokens } from "../../theme";
const riskColors = {
  FULL_CURTAILMENT: "rgb(218, 30, 40, 0.5)",
  PARTIAL_CURTAILMENT: "rgb(255, 131, 43, 0.5)",
  LOCAL_PRICE_RISK: "rgb(241, 194, 27, 0.5)",
};

const AssetConstraintTableGanttChart = ({ constraints }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const columns = [
    {
      field: "outageid",
      headerName: "OUTAGE ID",
      flex: 0.5,
      hide: true,
    },
    {
      field: "genconsetid",
      headerName: "CONSTRAINT SET",
      flex: 1,
    },
    { field: "genconid", headerName: "CONSTRAINT", flex: 1.5 },
    {
      field: "description",
      headerName: "DESCRIPTION",
      flex: 0.75,
      hide: true,
    },
    { field: "duid", headerName: "DUID", flex: 0.75 },
    { field: "starttime", headerName: "START TIME", flex: 1 },
    { field: "endtime", headerName: "END TIME", flex: 1 },
    { field: "equipmentid", headerName: "ID", flex: 0.75 },
    { field: "equipmenttype", headerName: "TYPE", flex: 0.5 },
    {
      field: "substationid",
      headerName: "SUBSTATION ID",
      flex: 1,
    },
    // {
    //   field: "substationdescription",
    //   headerName: "SUBSTATION DESCRIPTION",
    //   flex: 1,
    // },
    // {
    //   field: "regionid",
    //   headerName: "REGION",
    //   flex: 0.5,
    // },
    // {
    //   field: "ownerid",
    //   headerName: "OWNER",
    //   flex: 0.5,
    // },
    // {
    //   field: "voltage",
    //   headerName: "VOLTAGE",
    //   flex: 0.5,
    // },
    // {
    //   field: "equipmentdescription",
    //   headerName: "EQUIPMENT DESCRIPTION",
    //   flex: 1,
    // },
    {
      field: "outagestatuscode",
      headerName: "STATUS",
      flex: 0.5,
    },
    {
      field: "risk",
      headerName: "RISK",
      flex: 1,
      renderCell: (params) => {
        const riskLevel = params.value; // Value of the "risk" field
        const chipColor = riskColors[riskLevel] || "default"; // Fallback to "default" if not found
        return (
          <Chip
            label={riskLevel}
            style={{
              backgroundColor: chipColor,
              color: "white",
              fontWeight: "bold",
            }}
            size="small"
          />
        );
      },
    },
    { field: "bidtype", headerName: "BIDTYPE", flex: 0.75 },
    { field: "limittype", headerName: "LIMIT TYPE", flex: 1 },
    {
      field: "lastchanged",
      headerName: "LAST CHANGED",
      flex: 1,
      hide: true,
    },
  ];

  const firstRowAggregation = {
    apply: ({ values }) => (values.length > 0 ? values[0] : null),
    columnTypes: ["string", "number", "date", "dateTime"],
  };

  return (
    <Box
      sx={{
        width: "100%",
        "& .name-column--cell": {
          color: colors.grey[100],
        },
        "& .MuiDataGrid-columnHeader": {
          backgroundColor: colors.tableheader,
          borderBottom: "none",
          fontSize: "12px",
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: colors.grey[200],
        },
        "& .MuiDataGrid-cell": {
          fontFamily: "Source Sans Pro, sans-serif", // Font for cell text
          fontSize: "12px",
        },
      }}
    >
      <DataGridPremium
        rows={constraints}
        columns={columns}
        pageSize={200}
        pageSizeOptions={[100, 200, 300, 400, 500]} // Limit selectable page sizes to 200
        pagination
        density={"compact"}
        rowGroupingColumnMode="multiple"
        aggregationFunctions={{
          firstRow: firstRowAggregation,
        }}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        initialState={{
          pageSize: 200,
          rowGrouping: {
            model: ["outageid"],
          },
          aggregation: {
            model: {
              genconsetid: "firstRow",
              genconid: "firstRow",
              duid: "firstRow",
              starttime: "firstRow",
              endtime: "firstRow",
              equipmentid: "firstRow",
              equipmenttype: "firstRow",
              substationid: "firstRow",
              outagestatuscode: "firstRow",
              risk: "firstRow",
              bidtype: "firstRow",
              limittype: "firstRow",
              substationdescription: "firstRow",
              regionid: "firstRow",
              ownerid: "firstRow",
              voltage: "firstRow",
              equipmentdescription: "firstRow",
              // Add other columns as needed
            },
          },
          groupingExpansion: { "*": true }, // Expand all groups
          columns: {
            columnVisibilityModel: {
              outageid: false,
              starttime: false,
              endtime: false,
              equipmentid: false,
              substationid: false,
              voltage: false,
              equipmentdescription: false,
              limittype: false,
              bidtype: false,
              genconsetid: false,
              genconid: false,
              description: false,
              lastchanged: false,
            },
          },
        }}
      />
    </Box>
  );
};

export default AssetConstraintTableGanttChart;
