import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { createContext, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const DataContext = createContext();

export default DataContext;

export const DataProvider = ({ children }) => {
  let [duid, setDUID] = useState(() =>
    localStorage.getItem("duid")
      ? JSON.parse(localStorage.getItem("duid"))
      : null
  );
  let [startdate, setStartDate] = useState(() =>
    localStorage.getItem("startdate")
      ? JSON.parse(localStorage.getItem("startdate"))
      : dayjs()
  );
  let [enddate, setEndDate] = useState(() =>
    localStorage.getItem("enddate")
      ? JSON.parse(localStorage.getItem("enddate"))
      : dayjs().add(7, "day")
  );
  let [lastdatetime, setLastDateTime] = useState(false);
  let [filedatetime, setFileDateTime] = useState(false);
  let [filedatetimes, setFileDateTimes] = useState([]);
  let [duids, setDUIDs] = useState(() =>
    localStorage.getItem("searchList")
      ? JSON.parse(localStorage.getItem("searchList"))
      : []
  );
  let [portfolio, setPortfolio] = useState([]); // State to hold the list of DUIDs
  let [constraintsPortfolio, setConstraintsPortfolio] = useState([]); // State to hold new DUIDs input
  let [constraints, setConstraints] = useState([]);
  let [duidConstraints, setDUIDConstraints] = useState([]);
  let [duidConstraintSets, setDUIDConstraintSets] = useState([]);
  let [constraintSets, setConstraintSets] = useState([]);
  let [constraintSetsAll, setConstraintSetsAll] = useState([]);
  let [menu, setMenu] = useState("Dashboard");
  let [sectionMenu, setSectionMenu] = useState("DUID Specific");
  let [loading, setLoading] = useState(true);
  let [refresh, setRefresh] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const changeDUID = (e, val) => {
    localStorage.setItem("duid", JSON.stringify(val));
    setDUID(val);
    getNetworkOutageConstraints(val, startdate, enddate);
    navigate("/demo");
  };

  const changeFileDateTime = (authTokens, filedate) => {
    setFileDateTime(filedate);

    switch (menu) {
      case "All Outages":
        getNetworkOutageConstraintAll(authTokens, filedate);
        break;

      case "Constraints Only":
        getNetworkOutageConstraintSet(authTokens, filedate);
        break;

      case "Dashboard":
        getNetworkOutageConstraints(duid, startdate, enddate, filedate);
        break;

      case "Portfolio":
        getNetworkConstraintsPortfolio(startdate, enddate, filedate);
        break;

      default:
      // Default case when no menu type matches
    }
  };

  const changeEndDate = (edate) => {
    // localStorage.setItem("enddate", dayjs(edate));

    setEndDate(edate);

    if (menu === "Portfolio") {
      getNetworkConstraintsPortfolio(startdate, edate, filedatetime);
      navigate("/portfolio");
      setMenu("Portfolio");
    } else if (menu !== "Portfolio") {
      getNetworkOutageConstraints(duid, startdate, edate, filedatetime);
      if (localStorage.getItem("userDetails") !== null) {
        setMenu("Dashboard");
        navigate("/dashboard");
      } else {
        navigate("/demo");
      }
    }
  };

  const _dashboardChange = (val, startdate, enddate) => {
    getNetworkOutageConstraints(val, startdate, enddate, filedatetime);
    localStorage.setItem("menu", "Dashboard");
    setMenu("Dashboard");
    navigate("/dashboard");
  };

  const _constraintChange = (authTokens, val) => {
    getDuidSpecificConstraints(authTokens, val);
    navigate("/constraint/list");
  };

  const _constraintSetsChange = (authTokens, val) => {
    getDuidSpecificConstraintSets(authTokens, val);
    navigate("/constraint_set/list");
  };

  const changeDUIDAuth = (authTokens, val) => {
    localStorage.setItem("duid", JSON.stringify(val));
    setDUID(val);
    loadDuidConstraintData(authTokens, val);
  };

  const loadDuidConstraintData = (authTokens, val) => {
    if (menu === "Constraints") {
      _constraintChange(authTokens, val);
    } else if (menu === "Constraint Sets") {
      _constraintSetsChange(authTokens, val);
    } else {
      _dashboardChange(val, startdate, enddate);
    }
    setConstraints([]);
    setDUIDConstraints([]);
    setDUIDConstraintSets([]);
  };

  let getNetworkOutageConstraints = async (
    duid,
    startdate,
    enddate,
    filedatetime
  ) => {
    setRefresh(true);
    const encodedDuid = encodeURIComponent(duid);
    const start_date = dayjs(startdate).format("YYYY-MM-DD");
    const end_date = dayjs(enddate).format("YYYY-MM-DD");
    const file_date = dayjs(filedatetime).format("YYYY-MM-DDTHH:mm:ss");

    let response = await fetch(
      `/data/network_outages/${encodedDuid}/${start_date}/${end_date}/${file_date}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    let data = await response.json();

    if (response.status === 200) {
      setConstraints(data);
    } else {
      setConstraints([]);
    }
    setRefresh(false);
  };

  let getNetworkConstraintsPortfolio = async (
    startdate,
    enddate,
    filedatetime
  ) => {
    setRefresh(true);
    const start_date = dayjs(startdate).format("YYYY-MM-DD");
    const end_date = dayjs(enddate).format("YYYY-MM-DD");
    const file_date = dayjs(filedatetime).format("YYYY-MM-DDTHH:mm:ss");

    let response = await fetch(
      `/data/network_outages_portfolio/${start_date}/${end_date}/${file_date}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "JWT " +
            String(JSON.parse(localStorage.getItem("authTokens")).access),
        },
      }
    );

    let data = await response.json();

    if (response.status === 200) {
      setConstraintsPortfolio(data);
    } else {
      setConstraintsPortfolio([]);
    }
    setRefresh(false);
  };

  let getDuidSpecificConstraints = async (authTokens, duid) => {
    setRefresh(true);
    const encodedDuid = encodeURIComponent(duid);
    let response = await fetch(`/data/constraints/${encodedDuid}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(authTokens.access),
      },
    });

    let data = await response.json();

    if (response.status === 200) {
      setDUIDConstraints(data);
    } else {
      setDUIDConstraints([]);
    }
    setRefresh(false);
  };

  let getDuidSpecificConstraintSets = async (authTokens, duid) => {
    setRefresh(true);
    const encodedDuid = encodeURIComponent(duid);
    let response = await fetch(`/data/constraint_sets/${encodedDuid}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(authTokens.access),
      },
    });

    let data = await response.json();

    if (response.status === 200) {
      setDUIDConstraintSets(data);
      //   setRefresh(false);
    } else {
      setDUIDConstraintSets([]);
      //   setRefresh(false);
    }
    setRefresh(false);
  };

  let getNetworkOutageConstraintSet = async (authTokens, filedatetime) => {
    const file_date = dayjs(filedatetime).format("YYYY-MM-DDTHH:mm:ss");
    setRefresh(true);
    let response = await fetch(
      `/data/network_outage_joined_only_gen_con_set/${file_date}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(authTokens.access),
        },
      }
    );

    let data = await response.json();

    if (response.status === 200) {
      setConstraintSets(data);
      // return data
    } else {
      setConstraintSets([]);
      // return []
    }
    setRefresh(false);
  };

  let getNetworkOutageConstraintAll = async (authTokens, filedatetime) => {
    const file_date = dayjs(filedatetime).format("YYYY-MM-DDTHH:mm:ss");
    setRefresh(true);
    let response = await fetch(`/data/network_outage_joined/${file_date}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(authTokens.access),
      },
    });

    let data = await response.json();

    if (response.status === 200) {
      setConstraintSetsAll(data);
      // return data
    } else {
      setConstraintSetsAll([]);
      // return []
    }
    setRefresh(false);
  };

  // Function to fetch the DUIDs within Portfolio
  const getPortfolio = async (authTokens) => {
    setRefresh(true);
    try {
      const response = await fetch(`/auth/portfolio/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(authTokens.access),
        },
      });
      if (response.ok) {
        const data = await response.json();
        setPortfolio(data.duids); // Update state with the new list of DUIDs
        setRefresh(false);
        return data.duids;
      } else {
        console.error("Failed to fetch DUIDs:", response.statusText);
        setRefresh(false);
      }
    } catch (error) {
      console.error("Error fetching DUIDs:", error);
      setRefresh(false);
    }
    setRefresh(false);
  };

  let getCompleteDUIDList = async () => {
    setRefresh(true);
    let response = await fetch("/data/duids/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    let data = await response.json();
    if (response.status === 200) {
      return data;
    } else if (response.statusText === "Unauthorized") {
      logoutUser();
    }
    setRefresh(false);
  };

  let getDUIDs = useCallback(async () => {
    let response = await fetch("/data/duids/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    let data = await response.json();
    if (response.status === 200) {
      setDUIDs(data);
      return data;
    } else if (response.statusText === "Unauthorized") {
      logoutUser();
    }
  }, []); // if variable changes, useEffect will run again

  let getLastDateTime = useCallback(async () => {
    let response = await fetch("/data/latest/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    let data = await response.json();
    if (response.status === 200) {
      setLastDateTime(data.filedatetime);
      return data.filedatetime;
    } else if (response.statusText === "Unauthorized") {
      setLastDateTime(false);
      return false;
    }
  }, []); // if variable changes, useEffect will run again

  let getFileDateTimes = useCallback(async () => {
    let response = await fetch("/data/filedatetimes/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    let data = await response.json();
    if (response.status === 200) {
      setFileDateTimes(data);
    } else if (response.statusText === "Unauthorized") {
      setFileDateTimes([]);
    }
  }, []); // if variable changes, useEffect will run again

  const preLoadData = async (authTokens) => {
    getNetworkOutageConstraintSet(authTokens);
    getNetworkOutageConstraintAll(authTokens);
    setLoading(false);
  };

  let contextData = {
    duid: duid,
    duids: duids,
    menu: menu,
    sectionMenu: sectionMenu,
    portfolio: portfolio,
    loading: loading,
    refresh: refresh,
    constraints: constraints,
    startdate: startdate,
    enddate: enddate,
    lastdatetime: lastdatetime,
    filedatetime: filedatetime,
    filedatetimes: filedatetimes,
    duidConstraints: duidConstraints,
    duidConstraintSets: duidConstraintSets,
    constraintSets: constraintSets,
    constraintSetsAll: constraintSetsAll,
    constraintsPortfolio: constraintsPortfolio,
    setMenu: setMenu,
    setSectionMenu: setSectionMenu,
    changeDUID: changeDUID,
    changeEndDate: changeEndDate,
    setStartDate: setStartDate,
    setEndDate: setEndDate,
    preLoadData: preLoadData,
    setPortfolio: setPortfolio,
    getPortfolio: getPortfolio,
    changeDUIDAuth: changeDUIDAuth,
    setConstraints: setConstraints,
    setFileDateTime: setFileDateTime,
    getLastDateTime: getLastDateTime,
    getFileDateTimes: getFileDateTimes,
    changeFileDateTime: changeFileDateTime,
    setDUIDConstraints: setDUIDConstraints,
    getCompleteDUIDList: getCompleteDUIDList,
    setConstraintsPortfolio: setConstraintsPortfolio,
    loadDuidConstraintData: loadDuidConstraintData,
    getNetworkOutageConstraintSet: getNetworkOutageConstraintSet,
    getNetworkOutageConstraintAll: getNetworkOutageConstraintAll,
    getNetworkConstraintsPortfolio: getNetworkConstraintsPortfolio,
  };

  useEffect(() => {
    const initalLoad = async () => {
      const lastdate = await getLastDateTime();
      getDUIDs();
      getFileDateTimes();
      setFileDateTime(lastdate);
    };

    initalLoad();

    let fiveMinutes = 1000 * 60 * 5;
    let interval = setInterval(() => {
      getLastDateTime();
      getDUIDs();
      getFileDateTimes();
    }, fiveMinutes);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDUIDs, getLastDateTime, getFileDateTimes]);
  return (
    <DataContext.Provider value={contextData}>{children}</DataContext.Provider>
  );
};
