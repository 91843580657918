import {
  Box,
  Checkbox,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import Refresh from "../../components/Refresh";
import Header from "../../components/section-headers/Header";
import NOSOutagesAllTable from "../../components/tables/NOSOutagesAllTable";
import AuthContext from "../../context/AuthContext";
import DataContext from "../../context/DataContext";
import { tokens } from "../../theme";

const NetworkOutageAll = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let { authTokens } = useContext(AuthContext);
  let {
    refresh,
    constraintSetsAll,
    getNetworkOutageConstraintAll,
    filedatetime,
  } = useContext(DataContext);
  const [genconids, setGenconids] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([
    "SUBMIT",
    "RESUBMIT",
    "UTP",
    "MTLTP",
    "STLTP",
    "PDLTP",
    "PTP",
    "PTR",
  ]);
  const [filteredoutages, setFilteredconstraints] = useState([]);

  const callConstraints = async () => {
    getNetworkOutageConstraintAll(authTokens, filedatetime);

    // setConstraints(_constraintSet);
  };

  useEffect(() => {
    if (constraintSetsAll.length === 0) {
      callConstraints();
    } else {
      // nothing
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filteredConstraints = constraintSetsAll.filter((constraint) =>
      selectedStatuses.includes(constraint.outagestatuscode)
    );
    setFilteredconstraints(filteredConstraints);

    const genconsetidSelection = [
      ...new Set(filteredConstraints.map((constr) => constr.genconsetid)),
    ];
    setGenconids(genconsetidSelection);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [constraintSetsAll, selectedStatuses]);

  return (
    <Box m="5px">
      <Header
        title="All Network Outages"
        subtitle="List of All Network Outages in Latest NOS submission"
      />
      <Stack direction="row" spacing={2} mb={2}>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center", mb: 2 }}>
          {/* Status Filter */}
          <Box
            display="flex"
            alignItems="center"
            size="small"
            sx={{
              backgroundColor: colors.primary[400],
              borderRadius: "3px",
              padding: "0 0px ",
            }}
          >
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              p="0px 10px 0px 10px"
              sx={{
                color: colors.grey[100],
              }}
            >
              Filter by Status
            </Typography>
            <Select
              multiple
              value={selectedStatuses}
              onChange={(e) => setSelectedStatuses(e.target.value)}
              renderValue={(selected) => selected.join(", ")}
              size="small"
            >
              {[
                "SUBMIT",
                "UTP",
                "MTLTP",
                "STLTP",
                "PDLTP",
                "PTP",
                "PTR",
                "RESUBMIT",
                "WD REQ",
                "WDRAWN",
                "INFO",
                "COMPLETE",
              ].map((status) => (
                <MenuItem key={status} value={status}>
                  <Checkbox
                    checked={selectedStatuses.indexOf(status) > -1}
                    style={{
                      color:
                        selectedStatuses.indexOf(status) > -1
                          ? colors.greenAccent[400]
                          : undefined,
                    }}
                  />
                  {status}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </Stack>
      {(() => {
        if ((filteredoutages.length > 0) & (refresh === false)) {
          return <NOSOutagesAllTable constraints={filteredoutages} />;
        } else if (refresh === true) {
          return <Refresh refresh={refresh} />;
        } else {
          return null;
        }
      })()}
    </Box>
  );
};

export default NetworkOutageAll;
