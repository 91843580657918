// import "@bitnoi.se/react-scheduler/dist/style.css";
import HardwareIcon from "@mui/icons-material/Hardware";
import { Fragment, useCallback, useEffect, useState } from "react";
import "../react-calendar/dist/style.css";
// import { Scheduler } from "@bitnoi.se/react-scheduler";
import { Box, MenuItem, Select, Typography, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { tokens } from "../../theme";
import ConstraintTreeGanttConstraint from "../constraints/ConstraintTreeGanttConstraint";
import ConstraintTreeGanttDuid from "../constraints/ConstraintTreeGanttDuid";
import { Scheduler } from "../react-calendar/dist";
import SubTitle from "../section-headers/SubTitle";

const langs = [
  {
    id: "en",
    lang: {
      topbar: {
        filters: "FilterToday",
        next: "next",
        prev: "prev",
        today: "Today",
        view: "View",
      },
      search: "search",
      week: "week",
    },
  },
];

const riskLevels = {
  LOCAL_PRICE_RISK: 0,
  PARTIAL_CURTAILMENT: 1,
  FULL_CURTAILMENT: 2,
};

function RiskEvaluator(riskData) {
  if (riskData) {
    let worstRiskLevel = -1; // Initialize with a value lower than all risk levels

    // Iterate through the risk data to find the worst risk
    Object.keys(riskData).forEach((item) => {
      const riskValue = riskData[item].risk;
      // Compare the current risk level with the worst one found so far
      if (
        riskLevels[riskValue] !== undefined &&
        riskLevels[riskValue] > worstRiskLevel
      ) {
        worstRiskLevel = riskLevels[riskValue];
      }
    });

    // Map the worst risk level back to the corresponding key
    const worstRiskKey = Object.keys(riskLevels).find(
      (key) => riskLevels[key] === worstRiskLevel
    );
    return worstRiskKey;
  }
}

var colorDict = {};
colorDict["FULL_CURTAILMENT"] = "rgb(218, 30, 40, 0.5)";
colorDict["PARTIAL_CURTAILMENT"] = "rgb(255, 131, 43, 0.5)";
colorDict["LOCAL_PRICE_RISK"] = "rgb(241, 194, 27, 0.5)";

const ExportData = (data) => {
  {
    const new_data = data.genconsetidSelection.map(
      (genconsetid, genconsetidIndex) => {
        // setRender(true)
        const genconsetid_array = data.constraints.filter(
          (constr) => constr.genconsetid === genconsetid
        );

        const bgColor = `rgb(${Math.ceil(Math.random() * 255)},${Math.ceil(
          Math.random() * 200
        )},${Math.ceil(Math.random() * 200)}, 0.5)`;

        function getUnique(arr, index) {
          const unique = arr
            .map((e) => e[index])

            // store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)

            // eliminate the dead keys & store unique objects
            .filter((e) => arr[e])
            .map((e) => arr[e]);

          return unique;
        }

        const res = genconsetid_array.map((constr) => {
          var startDate = new Date(constr.starttime);
          // Do your operations
          var endDate = new Date(constr.endtime);
          var seconds = (endDate.getTime() - startDate.getTime()) / 1000;

          const _description = () => {
            if (constr.description !== undefined) {
              return constr.description.split(",")[0];
            } else {
              return null;
            }
          };

          const _colour = () => {
            if (constr.description !== undefined) {
              return colorDict[constr.risk];
            } else {
              return bgColor;
            }
          };

          const unique_description = _description();
          const unique_colour = _colour();

          return {
            idConstraint: `${constr.genconsetid}-${constr.starttime}`,
            idDuid: `${constr.genconsetid}-${constr.starttime}-${constr.duid}`,
            consetid: `${constr.genconsetid}`,
            duid: `${constr.duid}`,
            risk: `${constr.risk}`,
            startDate: constr.starttime,
            endDate: constr.endtime,
            occupancy: seconds,
            title: `${constr.genconsetid} | ${constr.outagestatuscode}`,
            subtitle: `${constr.starttime} to ${constr.endtime}`,
            description: unique_description,
            bgColor: unique_colour,
          };
        });
        // const unique_res = Array.from(new Set(res));

        const unique_duid = getUnique(res, "idDuid");
        const unique_cons = getUnique(res, "idConstraint");

        unique_cons.forEach((constr) => {
          constr.meta = genconsetid_array;
          if (constr.description !== undefined) {
            constr.color = colorDict[RiskEvaluator(res)];
          }
        });
        const consetid = {
          id: `${genconsetidIndex}-${genconsetid}`,
          label: {
            icon: <HardwareIcon />,
            title: genconsetid,
            subtitle: unique_duid[0].description,
            meta: unique_cons,
          },
          data: unique_cons,
        };
        return consetid;
      }
    );
    return new_data;
  }
};

export function GanttChart(data) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [filterButtonState, setFilterButtonState] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(0);
  const [constraintData, setConstraintData] = useState([]);
  const [constraintsGantt, setConstraintsGantt] = useState([]);
  const [constraintSetGantt, setConstraintSetGantt] = useState([]);
  const [showConstraint, setShowConstraint] = useState(false);

  const [range, setRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [open, setOpen] = useState(false);
  const [openSet, setOpenSet] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenSet = () => {
    setOpenSet(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenSet(false);
  };
  const handleRangeChange = useCallback((range) => {
    setRange(range);
  }, []);

  const handleViewTypeChange = (event) => {
    setShowConstraint(event.target.value);
  };

  const handleClickResource = (clickedResource) => {
    setConstraintsGantt(clickedResource);
    handleClickOpen();
  };

  const handleClickItem = (item) => {
    setConstraintSetGantt(item.label);
    handleClickOpenSet();
  };

  const orderedConstraintData = constraintData.sort((a, b) =>
    a.label.title.localeCompare(b.label.title)
  );
  // Get today's date
  const today = new Date();
  const currentDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  useEffect(() => {
    const dataLoad = ExportData(data);
    setConstraintData(dataLoad);
    setFilteredData(dataLoad);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      id="gantt-chart"
      height="70vh"
      width="100%"
      position="relative"
      sx={{ borderRadius: "15px", overflow: "hidden" }}
    >
      <Scheduler
        data={filteredData}
        isLoading={isLoading}
        onRangeChange={handleRangeChange}
        onTileClick={(clickedResource) => handleClickResource(clickedResource)}
        onItemClick={(item) => handleClickItem(item)}
        onFilterData={() => {
          // Some filtering logic...
          const filteredDataNew = constraintData
            .filter((constraintset) => {
              const value = constraintset.data.filter((item) => {
                // Convert startDate and endDate to Date objects
                const _startDate = new Date(item.startDate);
                const _endDate = new Date(item.endDate);
                const startDate = new Date(
                  _startDate.getFullYear(),
                  _startDate.getMonth(),
                  _startDate.getDate()
                );
                const endDate = new Date(
                  _endDate.getFullYear(),
                  _endDate.getMonth(),
                  _endDate.getDate()
                );

                // Check if today's date is within the range
                return startDate <= currentDate && endDate >= currentDate;
              });
              if (value && value.length > 0) {
                return true;
              }
            })
            .map((constraintset) => {
              return {
                ...constraintset,
                data: constraintset.data.filter((item) => {
                  // Convert startDate and endDate to Date objects
                  const _startDate = new Date(item.startDate);
                  const _endDate = new Date(item.endDate);
                  const startDate = new Date(
                    _startDate.getFullYear(),
                    _startDate.getMonth(),
                    _startDate.getDate()
                  );
                  const endDate = new Date(
                    _endDate.getFullYear(),
                    _endDate.getMonth(),
                    _endDate.getDate()
                  );

                  // Check if today's date is within the range
                  return startDate <= currentDate && endDate >= currentDate;
                }),
              };
            });

          setFilteredData(filteredDataNew);
          setFilterButtonState(1);
        }}
        onClearFilterData={() => {
          // Some clearing filters logic...
          setFilteredData(orderedConstraintData);
          setFilterButtonState(0);
        }}
        config={{
          zoom: 1,
          filterButtonState,
          lang: "en",
          translations: langs,
          showTooltip: 0,
          defaultTheme: "dark",
          maxRecordsPerPage: 300,
        }}
      />

      <Dialog fullWidth={false} maxWidth="md" open={open} onClose={handleClose}>
        <Box
          noValidate
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            m: "auto",
            width: "fit-content",
          }}
        >
          <DialogContent>
            <SubTitle
              title={`${constraintsGantt.consetid}`}
              subtitle={`${
                constraintsGantt.description !== null
                  ? constraintsGantt.description
                  : constraintsGantt.title
              }`}
              text={`start date: ${constraintsGantt.startDate}, end date: ${constraintsGantt.endDate}`}
            />
            {constraintsGantt.description !== null ? (
              <Fragment>
                <Select
                  value={showConstraint}
                  // label="User Type"
                  onChange={handleViewTypeChange}
                  size="small"
                  color="secondary"
                  sx={{
                    mb: "10px",
                  }}
                >
                  <MenuItem value={true}>Constraints</MenuItem>
                  <MenuItem value={false}>DUIDs/ICs/Regions</MenuItem>
                </Select>

                {showConstraint ? (
                  <ConstraintTreeGanttConstraint
                    constraints={constraintsGantt.meta}
                  />
                ) : (
                  <ConstraintTreeGanttDuid
                    constraints={constraintsGantt.meta}
                  />
                )}
              </Fragment>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Close
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      <Dialog
        fullWidth={false}
        maxWidth="md"
        open={openSet}
        onClose={handleClose}
      >
        <Box
          noValidate
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            m: "auto",
            width: "fit-content",
          }}
        >
          <DialogContent>
            <SubTitle
              title={`${constraintSetGantt.title}`}
              subtitle={`${
                constraintSetGantt.subtitle !== null
                  ? constraintSetGantt.subtitle
                  : constraintSetGantt.meta[0].title
              }`}
              text={`Here is a list of start date and end dates for the constraint set. 
                Click on individual outages to see more information about impacted Assets and Constraints.`}
            />
            {constraintSetGantt.meta !== undefined
              ? constraintSetGantt.meta.map((item) => {
                  return (
                    <Fragment>
                      <Typography
                        sx={{ display: "inline-block" }}
                      >{`${item.title}`}</Typography>
                      {"     "}
                      <Typography
                        sx={{ display: "inline-block" }}
                        color="warning"
                      >{`${item.subtitle}`}</Typography>
                      <Typography color="warning"></Typography>
                    </Fragment>
                  );
                })
              : null}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Close
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
}
