import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Header from "../../components/section-headers/Header";
import Account from "./Account";
import ManagePortfolio from "./ManagePortfolio";

const Profile = () => {
  return (
    <Box m="20px" height="100%">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="Account Profile"
          subtitle="Managing your Account Profile and Porfolio"
        />
      </Box>
      <Box height="100%">
        <Grid container spacing={2} flexGrow={1} columns={{ xs: 12, sm: 12 }}>
          <Grid
            flexGrow={1}
            xs={12}
            sm={6}
            md={4}
            lg={4}
            minWidth="375px"
            maxWidth="375px"
          >
            <ManagePortfolio />
          </Grid>
          <Grid flexGrow={1} xs={12} sm={6} md={4} lg={4}>
            <Account />
          </Grid>
          <Grid minWidth="25%" xs={0} sm={0} md={2} lg={2}></Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Profile;
