import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";

const Header = ({ title, subtitle }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box mb="10px">
      <Typography
        variant="h4"
        color={colors.grey[100]}
        fontWeight="bold"
        sx={{ mb: "3px" }}
      >
        {title}
      </Typography>
      <Typography variant="title" color={colors.text}>
        {subtitle}
      </Typography>
    </Box>
  );
};

export default Header;
