import { Box, Stack, Typography, useTheme } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useContext } from "react";
import ProDateRangePicker from "../../components/DateRangePicker";
import DataContext from "../../context/DataContext";
import { tokens } from "../../theme";

function TopbarUnauthorized() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  let { changeDUID, duids, lastdatetime } = useContext(DataContext);

  return (
    <Box p={2}>
      <Box display="flex" justifyContent="space-between">
        {/* SEARCH BAR */}
        <Stack direction="row" spacing={2}>
          <Box
            display="flex"
            backgroundColor={colors.primary[400]}
            borderRadius="3px"
          >
            <Stack
              spacing={1}
              sx={{
                width: 200,
                flex: 1,
                "&& .MuiInputBase-input": { minHeight: "20px", height: "1rem" },
              }}
            >
              <Autocomplete
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                options={duids.map((duid) => duid.duid)}
                onChange={changeDUID}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search DUIDs, ICs and Regions"
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                )}
              />
            </Stack>
            {/* <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton> */}
          </Box>
          <Box
            display="flex"
            sx={{
              "& .MuiInputBase-input-MuiInput-input": {
                minWidth: "0px",
                padding: "8px",
                paddingRight: "24px",
              },
              "& .MuiSelect-icon": {
                top: "calc(50% - 1.25em)",
              },
            }}
          >
            <ProDateRangePicker />
          </Box>
        </Stack>

        {/* ICONS */}
      </Box>
      <Box
        display="flex"
        sx={{
          "& .MuiInputBase-input-MuiInput-input": {
            minWidth: "0px",
            padding: "8px",
            paddingRight: "24px",
          },
          "& .MuiSelect-icon": {
            top: "calc(50% - 1.25em)",
          },
        }}
      >
        <Box>
          {/* <MenuItem component={Link} to="/dashboard"> */}
          <Typography color={colors.grey[100]} variant="h7" pr="5px">
            Last Updated:
          </Typography>
          <Typography color={colors.greenAccent[500]} variant="h7">
            {lastdatetime}
          </Typography>
          {/* </MenuItem> */}
        </Box>
      </Box>
    </Box>
  );
}

export default TopbarUnauthorized;
