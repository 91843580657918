import {
  Box,
  CircularProgress,
  MenuItem,
  Select,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProDateRangePicker from "../../components/DateRangePicker";
import AuthContext from "../../context/AuthContext";
import DataContext from "../../context/DataContext";
import { ColorModeContext, tokens } from "../../theme";

// Extend dayjs with timezone plugins
dayjs.extend(utc);
dayjs.extend(timezone);

function Topbar() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const colorMode = useContext(ColorModeContext);
  let { user, authTokens, logoutUser } = useContext(AuthContext);
  let {
    changeDUIDAuth,
    duids,
    lastdatetime,
    filedatetimes,
    getFileDateTimes,
    getLastDateTime,
    filedatetime,
    setFileDateTime,
    changeFileDateTime,
  } = useContext(DataContext);

  let navigate = useNavigate();

  const [currentTime, setCurrentTime] = useState(
    dayjs().tz("Australia/Brisbane")
  );
  const [progress, setProgress] = useState(0);
  const [highlightRed, setHighlightRed] = useState(false);
  const [shouldCheck, setShouldCheck] = useState(false);

  // Update time and progress every second
  useEffect(() => {
    const timer = setInterval(() => {
      const now = dayjs().tz("Australia/Brisbane");
      setCurrentTime(now);

      // Calculate progress percentage for the current half-hour
      const minutes = now.minute();
      const seconds = now.second();
      const totalSeconds = minutes * 60 + seconds;
      const progressPercentage = ((totalSeconds % 1800) / 1800) * 100;
      setProgress(progressPercentage);

      // Check if it's the start of a new half-hour period
      if (minutes === 0 || minutes === 30) {
        setHighlightRed(true); // Highlight red at the beginning of the half-hour
        setShouldCheck(false); // Disable checking initially
      }

      // Enable checking for updates after 2 minutes into the half-hour
      if ((minutes === 2 || minutes === 32) && !shouldCheck) {
        setShouldCheck(true);
      }
    }, 1000);

    return () => clearInterval(timer); // Cleanup on unmount
  }, [shouldCheck]);

  // Fetch `lastdatetime` updates when `shouldCheck` is enabled
  useEffect(() => {
    if (shouldCheck) {
      const interval = setInterval(() => {
        getLastDateTime(authTokens); // Fetch latest datetime
      }, 10000); // Check every 10 seconds

      return () => clearInterval(interval); // Cleanup on disable
    }
  }, [shouldCheck, authTokens, getLastDateTime]);

  // Reset highlight when `lastdatetime` is updated
  useEffect(() => {
    if (lastdatetime) {
      setHighlightRed(false); // Reset red highlight
      setShouldCheck(false); // Stop checking for updates
    }
  }, [lastdatetime]);

  useEffect(() => {
    // Fetch filedatetimes whenever lastdatetime updates
    if (lastdatetime) {
      getFileDateTimes();
    }
  }, [lastdatetime, getFileDateTimes]);

  // Highlight the dropdown red if filedatetime doesn't match lastdatetime
  const isFileDateMismatch = filedatetime && filedatetime !== lastdatetime;

  // Update time and progress every second
  useEffect(() => {
    const timer = setInterval(() => {
      const now = dayjs().tz("Australia/Brisbane");
      setCurrentTime(now);

      // Calculate progress percentage for the current half-hour
      const minutes = now.minute();
      const seconds = now.second();
      const totalSeconds = minutes * 60 + seconds;
      setProgress(((totalSeconds % 1800) / 1800) * 100); // 1800 seconds = 30 minutes
    }, 1000);

    return () => clearInterval(timer); // Cleanup on unmount
  }, []);

  let callLogout = () => {
    logoutUser();
    navigate("/");
  };

  let changeFileMethod = (val) => {
    setFileDateTime(val);
    changeFileDateTime(authTokens, val);
  };

  let changeDUIDMethod = (e, val) => {
    changeDUIDAuth(authTokens, val);
  };

  return (
    <Box pt={isSmallScreen ? 3 : 1.5} pb={1}>
      <Box
        display="flex"
        flexDirection={isSmallScreen ? "column" : "row"}
        justifyContent={isSmallScreen ? "start" : "space-between"}
        alignItems={isSmallScreen ? "stretch" : "center"}
        gap={2}
      >
        {/* SEARCH BAR AND DROPDOWN */}
        <Stack
          direction={isSmallScreen ? "column" : "row"}
          spacing={2}
          flex="1"
        >
          {/* Search Bar */}
          <Box
            display="flex"
            backgroundColor={colors.primary[400]}
            borderRadius="3px"
            flex={isSmallScreen ? "1" : "none"}
          >
            <Stack
              spacing={1}
              sx={{
                width: isSmallScreen ? "100%" : 200,
                "&& .MuiInputBase-input": { minHeight: "20px", height: "1rem" },
              }}
            >
              <Autocomplete
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                options={duids.map((duid) => duid.duid)}
                onChange={(e, new_val) => changeDUIDMethod(e, new_val)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search DUIDs, ICs or Regions"
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                )}
              />
            </Stack>
          </Box>

          {/* Date Range Picker */}
          <Box
            display="flex"
            sx={{
              "& .MuiInputBase-input-MuiInput-input": {
                minWidth: "0px",
                padding: "8px",
                paddingRight: "24px",
              },
              "& .MuiSelect-icon": {
                top: "calc(50% - 1.25em)",
              },
            }}
          >
            <ProDateRangePicker />
          </Box>

          {/* File Date Dropdown */}
          <Box
            display="flex"
            alignItems="center"
            size="small"
            sx={{
              backgroundColor: isFileDateMismatch
                ? "warning.dark"
                : colors.primary[400],
              borderRadius: "3px",
              padding: "0 10px",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: isFileDateMismatch ? "white" : colors.grey[100],
                pr: 0,
              }}
            >
              File Date:
            </Typography>
            <Select
              value={filedatetime || ""}
              size="small"
              onChange={(e) => changeFileMethod(e.target.value)}
              displayEmpty
              inputProps={{ label: "File Date Selector" }}
              sx={{
                color: isFileDateMismatch ? "white" : colors.grey[100],
                "& .MuiOutlinedInput-notchedOutline": { border: "none" },
              }}
            >
              <MenuItem value="" disabled>
                Select File Date
              </MenuItem>
              {filedatetimes
                .sort(
                  (a, b) => new Date(b.filedatetime) - new Date(a.filedatetime)
                ) // Sort descending
                .map((file) => (
                  <MenuItem key={file.filedatetime} value={file.filedatetime}>
                    {file.filedatetime}
                  </MenuItem>
                ))}
            </Select>
          </Box>
        </Stack>

        {/* RIGHT SIDE: Brisbane Time and Progress Circle */}
        <Box
          display="flex"
          // flexDirection={isSmallScreen ? "column" : "row"}
          alignItems="center"
          gap={2}
        >
          {/* Circular Progress */}
          <Box sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress
              variant="determinate"
              value={progress}
              size={40}
              thickness={4}
              sx={{ color: highlightRed ? "#ED655D" : colors.primary[200] }}
            />
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Typography
                variant="caption"
                component="div"
                sx={{ color: highlightRed ? "#ED655D" : "text.secondary" }}
              >
                {Math.floor((progress / 100) * 30)}m
              </Typography>
            </Box>
          </Box>

          {/* Brisbane Time Counter */}
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              color: highlightRed ? "#ED655D" : colors.grey[100],
            }}
          >
            {currentTime.format("HH:mm:ss")}
          </Typography>
        </Box>
      </Box>

      {/* Last Updated Section */}
      <Box mt={isSmallScreen ? 2 : 0}>
        <Typography color={colors.grey[100]} variant="h7" pr="5px">
          Last Updated:
        </Typography>
        <Typography color={colors.timestamp} variant="h7">
          {lastdatetime}
        </Typography>
      </Box>
    </Box>
  );
}

export default Topbar;
