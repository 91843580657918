import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";

const SectionText = ({ section, text }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box mb="30px">
      <Typography
        variant="h6"
        color={colors.text}
        fontWeight="bold"
        sx={{ mb: "5px" }}
      >
        {section}
      </Typography>
      <Typography variant="h7" color={colors.grey[100]}>
        {text}
      </Typography>
    </Box>
  );
};

export default SectionText;
