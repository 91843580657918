import { useSnackbar } from "notistack";
import { createContext, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { hotjar } from "react-hotjar";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  let [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  let [user, setUser] = useState(() =>
    localStorage.getItem("userDetails")
      ? JSON.parse(localStorage.getItem("userDetails"))
      : null
  );
  let [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const getUser = async (authTokens) => {
    let response = await fetch("/auth/users/me/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(authTokens.access),
      },
    });

    let data = await response.json();

    if (response.status === 200) {
      return data;
    } else {
      enqueueSnackbar("Issue retrieving account information.", {
        variant: "error",
      });
    }
  };

  let setUserDataLocalStorage = async (data) => {
    let userdata = await getUser(data);
    let userId = userdata.id; // Fetch the user's ID from your auth system
    ReactGA.set({ user_id: userId }); // Set the user ID in Google Analytics
    hotjar.identify("USER_ID", {
      userId: userId,
    });
    setUser(userdata);
    localStorage.setItem("userDetails", JSON.stringify(userdata));
  };

  let loginUser = async (e) => {
    // e.preventDefault()
    let response = await fetch("/auth/jwt/create/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: e.email.toLowerCase(),
        password: e.password,
      }),
    });

    let data = await response.json();

    if (response.status === 200) {
      setAuthTokens(data);
      navigate("/dashboard");
      localStorage.setItem("authTokens", JSON.stringify(data));
      setUserDataLocalStorage(data);
    } else {
      enqueueSnackbar(
        `Access Token Expired or Incorrect User Details. ${JSON.stringify(
          data
        )}"`,
        {
          variant: "error",
        }
      );
    }
  };

  let signupUser = async (e) => {
    // e.preventDefault()
    let response = await fetch("/auth/users/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: e.name,
        email: e.email.toLowerCase(),
        password: e.password,
        re_password: e.re_password,
      }),
    });

    let data = await response.json();

    if (response.status === 201) {
      enqueueSnackbar("Email Verification sent for Account Activation", {
        variant: "success",
      });
    } else {
      console.log(data);
      enqueueSnackbar(
        `Please ensure all details are correct. ${JSON.stringify(data)}"`,
        {
          variant: "warning",
        }
      );
    }
    navigate("/signup");
  };

  let signupAdminUser = async (authTokens, e) => {
    // e.preventDefault()
    let userdata = await getUser(authTokens);
    setUser(userdata);
    localStorage.setItem("userDetails", JSON.stringify(userdata));

    if (userdata.is_admin) {
      let response = await fetch("/auth/users/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(authTokens.access),
        },
        body: JSON.stringify({
          name: e.name,
          email: e.email.toLowerCase(),
          password: e.password,
          re_password: e.re_password,
          is_admin: e.is_admin,
        }),
      });

      if (response.status === 201) {
        enqueueSnackbar("Email Verification sent for Account Activation.", {
          variant: "success",
        });
        navigate("/account");
      } else {
        enqueueSnackbar("Please ensure all details are correct.", {
          variant: "warning",
        });
      }
    } else {
      enqueueSnackbar("Only Admins can Create or Delete Accounts.", {
        variant: "error",
      });
      navigate("/dashboard");
    }
  };

  const verifyUser = async (uid, token) => {
    await fetch("/auth/users/activation/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uid, token }),
    })
      .then((response) => {
        console.log(response.status);
        if (response.status === 204 || response.status === 302) {
          enqueueSnackbar("Account Activation Successful.", {
            variant: "success",
          });
          navigate("/login"); // Navigate to login after success
        } else {
          return response.json().then((data) => {
            enqueueSnackbar(
              `Account Activation Failed. Please try again. ${JSON.stringify(
                data
              )}`,
              { variant: "error" }
            );
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(`Something went wrong: ${error.message}`, {
          variant: "error",
        });
      });
  };

  let logoutUser = () => {
    setAuthTokens(null);
    setUser(null);
    localStorage.removeItem("authTokens");
    localStorage.removeItem("userDetails");
  };

  let resetPassword = async (e) => {
    // e.preventDefault()
    let response = await fetch("/auth/users/reset_password/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: e.email.toLowerCase() }),
    });

    if (response.status === 204) {
      enqueueSnackbar(`Password Reset Link sent to Email Inbox.`, {
        variant: "success",
      });
    } else {
      let data = await response.json();
      enqueueSnackbar(
        `Account Activation Failed. Try again. ${JSON.stringify(data)}`,
        {
          variant: "warning",
        }
      );
    }
    navigate("/");
  };

  const resetPasswordConfirm = async (
    uid,
    token,
    new_password,
    re_new_password
  ) => {
    let response = await fetch("/auth/users/reset_password_confirm/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uid: uid,
        token: token,
        new_password: new_password,
        re_new_password: re_new_password,
      }),
    });

    navigate("/login");

    if (response.status === 204) {
      enqueueSnackbar("Reset Password Successful.", {
        variant: "success",
      });
    } else {
      enqueueSnackbar("Reset Password Failed, Try Again.", {
        variant: "error",
      });
    }
  };

  let contextData = {
    user: user,
    authTokens: authTokens,
    loginUser: loginUser,
    logoutUser: logoutUser,
    resetPassword: resetPassword,
    resetPasswordConfirm: resetPasswordConfirm,
    verifyUser: verifyUser,
    signupUser: signupUser,
    signupAdminUser: signupAdminUser,
  };

  let updateToken = async () => {
    let response = await fetch("/auth/jwt/refresh/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refresh: authTokens?.refresh }),
    });

    let data = await response.json();

    if (response.status === 200) {
      setAuthTokens(data);
      localStorage.setItem("authTokens", JSON.stringify(data));
    } else {
      logoutUser();
    }

    if (loading) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loading) {
      updateToken();
    }

    let fourMinutes = 1000 * 60 * 4;
    let interval = setInterval(() => {
      if (authTokens) {
        updateToken();
      }
    }, fourMinutes);

    return () => clearInterval(interval);
  }); //, [authTokens, loading])

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};
