import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import { Alert, Box, Snackbar, Typography, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SectionHeader from "../../components/section-headers/SectionHeader";
import AuthContext from "../../context/AuthContext";
import { tokens } from "../../theme";

const Account = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [userEdit, setUserEdit] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  let { user, authTokens, logoutUser } = useContext(AuthContext);
  let [users, setUsers] = useState([]);

  const navigate = useNavigate();

  const isNonMobile = useMediaQuery("(min-width:600)");

  const getUsers = async (authTokens) => {
    let response = await fetch("/auth/users/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(authTokens.access),
      },
    });

    let data = await response.json();

    if (response.status === 200) {
      setUsers(data);
    } else {
      alert("Something went wrong " + String(response.status));
    }
  };

  const deleteUser = async (authTokens, email) => {
    let response = await fetch("/auth/users/delete_user/", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(authTokens.access),
      },
      body: JSON.stringify({ email: email }),
    });

    if (response.status === 204) {
      alert(`User account ${email} was deleted!`);
    } else if (response.status === 400) {
      alert("Bad Request: Email does not exist in Database");
    } else if (response.status === 401) {
      alert("Unauthorized User: Need to be Admin");
    } else {
      alert("Something went wrong " + String(response.status));
    }
    user.is_admin ? window.location.reload() : logoutUser();
  };

  useEffect(
    () => {
      getUsers(authTokens);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleRowClick = (params) => {
    setUserEdit(params.row.email);
    user.is_admin ? setOpenEdit(true) : setOpenEdit(true);
  };

  const handleUserDelete = () => {
    deleteUser(authTokens, userEdit);
    getUsers(authTokens);
    handleClose();
  };

  const handleClickOpen = () => {
    user.is_admin ? navigate("/createuser") : setOpenError(true);
  };

  const handleClose = () => {
    setUserEdit(false);
    setOpenError(false);
    setOpenEdit(false);
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 100,
      cellClassName: "name-column--cell",
    },
    { field: "email", headerName: "Email", flex: 1.75, minWidth: 175 },
    {
      field: "is_active",
      headerName: "Account Active",
      cellClassName: "name-column--cell",
      flex: 0.75,
      minWidth: 75,
    },
    {
      field: "is_admin",
      headerName: "Access Level",
      flex: 1.25,
      minWidth: 125,
      renderCell: ({ row: { is_admin } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              is_admin === "admin"
                ? colors.greenAccent[600]
                : colors.greenAccent[700]
            }
            borderRadius="4px"
          >
            {is_admin ? (
              <AdminPanelSettingsOutlinedIcon />
            ) : (
              <SecurityOutlinedIcon />
            )}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {is_admin ? "Admin" : "User"}
            </Typography>
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="5px" width="95%" height="100%">
      {user.is_admin ? (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <SectionHeader
            title="ACCOUNTS"
            subtitle="Managing the All Accounts"
          />
        </Box>
      ) : (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <SectionHeader title="ACCOUNT" subtitle="Managing Your Account" />
        </Box>
      )}

      {user.is_admin ? (
        <Box display="flex" justifyContent="start" mb="5px">
          <Button
            onClick={handleClickOpen}
            color="secondary"
            variant="contained"
          >
            Create New User
          </Button>
        </Box>
      ) : null}

      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        <Box
          height="55vh"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
            "& .name-column--cell": {
              color: colors.grey[100],
            },
            "& .MuiDataGrid-columnHeader": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: colors.grey[100],
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
          }}
        >
          <DataGrid
            rows={users}
            columns={columns}
            onRowClick={handleRowClick}
            density={"compact"}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        </Box>
      </Box>

      <Snackbar
        open={openError}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="warning"
          variant="outlined"
          sx={{ width: "100%" }}
        >
          You do not have Permission to Manage Team Members
        </Alert>
      </Snackbar>

      <Dialog
        open={openEdit}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`User Account: ${userEdit}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to permently delete {userEdit} as a user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="alerts" variant="contained" onClick={handleUserDelete}>
            Delete
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleClose}
            autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Account;
