import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, useTheme } from "@mui/material";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem2 } from "@mui/x-tree-view/TreeItem2";
import { tokens } from "../../theme";
import AssetConstraintTableGanttChart from "../tables/AssetConstraintTableGanttChart";
import ConstraintTitleGeneral from "./ConstraintTitleGeneral";
const ConstraintTreeGanttConstraint = (data) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const constraintSelection = [
    ...new Set(data.constraints.map((constr) => constr.genconid)),
  ];

  return (
    <SimpleTreeView
      aria-label="file system navigator"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      sx={{
        height: "100%",
        flexGrow: 1,
        width: "100%",
        overflowY: "auto",
        mb: "20px",
      }}
    >
      {constraintSelection.map((genconid) => {
        const constraintDUIDs = data.constraints.filter(
          (constr) => constr.genconid === genconid
        );

        const duidSelection = [
          ...new Set(constraintDUIDs.map((constr) => constr.duid)),
        ];

        return (
          <TreeItem2 itemId={`${genconid}`} label={`${genconid}`}>
            {duidSelection.map((duid, index) => {
              const duidConstraint = constraintDUIDs.filter(
                (constr) => constr.duid === duid
              );
              return (
                <Box sx={{ width: "100%" }}>
                  {index === 0 ? (
                    <ConstraintTitleGeneral
                      constraint={genconid}
                      description={
                        duidConstraint[index].description.split(",")[0]
                      }
                      reason={duidConstraint[index].reason}
                      type={duidConstraint[index].limittype}
                      value={duidConstraint[index].constraintvalue}
                    />
                  ) : null}

                  <TreeItem2 itemId={`${duid}-${genconid}`} label={`${duid}`}>
                    <AssetConstraintTableGanttChart
                      constraints={duidConstraint}
                    />
                  </TreeItem2>
                </Box>
              );
            })}
          </TreeItem2>
        );
      })}
    </SimpleTreeView>
  );
};

export default ConstraintTreeGanttConstraint;
