import "leaflet/dist/leaflet.css";
import { useEffect, useRef, useState } from "react";
import { Polyline, Popup, useMap } from "react-leaflet";

const OSMTransmission = ({ transmissionLines }) => {
  const map = useMap();
  const zoom = map.getZoom();
  const initialUpdateRef = useRef(false);
  // const [transmissionLines, setTransmissionLines] = useState([]);
  const [visibleLines, setVisibleLines] = useState([]);
  const [visibleVoltages, setVisibleVoltages] = useState([]);

  // Fetch transmission lines from Overpass API
  async function fetchTransmissionLines() {
    const overpassQuery = `
      [out:json][timeout:50000];
      area[name="Australia"]->.a;
      (
        way["power"="line"](area.a);
      );
      out geom tags;
    `;
    const url = `https://overpass-api.de/api/interpreter?data=${encodeURIComponent(
      overpassQuery
    )}`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      return data.elements; // Contains the OSM transmission lines
    } catch (error) {
      console.error("Error fetching transmission lines:", error);
      return [];
    }
  }

  // Organize transmission lines by voltage
  const organizeByVoltage = (lines) => {
    const grouped = {};
    lines.forEach((line) => {
      const voltage = line.tags?.voltage || "unknown";
      if (!grouped[voltage]) grouped[voltage] = [];
      grouped[voltage].push(line);
    });
    return grouped;
  };

  // Update visible voltages based on zoom level
  const updateVisibleVoltages = (zoomLevel) => {
    // All voltages
    if (zoomLevel <= 3) {
      setVisibleVoltages(["500000", "400000", "330000", "275000"]); // High voltage
    } else if (zoomLevel <= 5) {
      setVisibleVoltages(["500000", "400000", "330000", "275000", "220000"]); // High voltage
    } else if (zoomLevel <= 6) {
      setVisibleVoltages([
        "500000",
        "400000",
        "330000",
        "275000",
        "220000",
        "132000",
      ]); // High voltage
    } else if (zoomLevel <= 8) {
      setVisibleVoltages([
        "500000",
        "400000",
        "330000",
        "275000",
        "220000",
        "132000",
        "110000",
      ]); // Medium voltage
    } else if (zoomLevel <= 9) {
      setVisibleVoltages([
        "500000",
        "400000",
        "330000",
        "275000",
        "220000",
        "132000",
        "110000",
        "66000",
      ]); // Medium voltage
    } else if (zoomLevel <= 13) {
      setVisibleVoltages([
        "500000",
        "400000",
        "330000",
        "275000",
        "220000",
        "132000",
        "110000",
        "66000",
        "33000",
        "22000",
      ]); // Medium voltage
    } else if (zoomLevel <= 15) {
      setVisibleVoltages([
        "500000",
        "400000",
        "330000",
        "275000",
        "220000",
        "132000",
        "110000",
        "66000",
        "33000",
        "22000",
        "11000",
      ]); // Medium voltage
    } else {
      setVisibleVoltages([
        "500000",
        "400000",
        "330000",
        "275000",
        "220000",
        "132000",
        "110000",
        "66000",
        "33000",
        "11000",
      ]); // All voltages
    }
  };

  // Filter lines by viewport and voltage
  const filterLines = (bounds) => {
    return transmissionLines.filter(
      (line) =>
        visibleVoltages.includes(line.tags?.voltage) &&
        line.geometry.some((point) => bounds.contains([point.lat, point.lon]))
    );
  };

  // Monitor map viewport and update visible lines
  const MapViewportListener = () => {
    useEffect(() => {
      if (transmissionLines.length > 0) {
        const updateVisibleLines = () => {
          const bounds = map.getBounds();
          const zoom = map.getZoom();
          updateVisibleVoltages(zoom);
          const filteredLines = filterLines(bounds);
          setVisibleLines(filteredLines);
        };

        map.on("moveend", updateVisibleLines);
        map.on("zoomend", updateVisibleLines);

        if (!initialUpdateRef.current) {
          // Perform initial update only once
          updateVisibleLines();
          initialUpdateRef.current = true;
        }

        return () => {
          map.off("moveend", updateVisibleLines);
          map.off("zoomend", updateVisibleLines);
        };
      }
    }, [map]);
    return null;
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const lines = await fetchTransmissionLines();
  //     setTransmissionLines(lines);
  //   };
  //   fetchData();
  // }, []);

  // Function to style the lines
  const getLineStyle = (line) => {
    const voltage = parseInt(line.tags?.voltage, 10) || 0;
    let color;
    let weight;

    if (voltage > 330000) {
      color = "#FFDE21";
      weight = 3.5;
    } else if (voltage > 275000) {
      color = "#F2AA2E";
      weight = 3;
    } else if (voltage > 220000) {
      color = "#E5007E";
      weight = 2.75;
    } else if (voltage > 132000) {
      color = "#0100FF";
      weight = 2.5;
    } else if (voltage > 66000) {
      color = "#E4012D";
      weight = 2.25;
    } else {
      color = "#77410F";
      weight = 2;
    }

    return {
      color: color,
      weight: weight,
      opacity: 0.75,
    };
  };

  const renderLines = () => {
    return visibleLines.map((line) => {
      if (line.geometry) {
        const positions = line.geometry.map((point) => [point.lat, point.lon]);
        const { operator, voltage, wires, ref } = line.tags;

        return (
          <Polyline
            key={line.id}
            positions={positions}
            pathOptions={getLineStyle(line)}
            eventHandlers={{
              click: () => {
                console.log(line.tags); // Log the tags to the console
              },
            }}
          >
            <Popup>
              <div>
                <strong>OPERATING_VOLTAGE:</strong>{" "}
                {(parseInt(voltage, 10) || 0) / 1000 || ""} <br />
                <strong>OWNER:</strong> {operator || ""} <br />
                <strong>TYPE:</strong> {line.tags.line || "line"} <br />
                <strong>WIRES:</strong> {wires || ""} <br />
                <strong>REFERENCE:</strong> {ref || ""} <br />
              </div>
            </Popup>
          </Polyline>
        );
      }
      return null;
    });
  };

  return (
    <>
      <MapViewportListener />
      {renderLines()}
    </>
  );
};

export default OSMTransmission;
