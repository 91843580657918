import { Stack } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers-pro/LocalizationProvider";
import "dayjs/locale/en-gb";
import React, { useContext } from "react";
import DataContext from "../context/DataContext";

export default function ProDateRangePicker() {
  const { startdate, enddate, setStartDate, changeEndDate } =
    useContext(DataContext);

  const handleDateRangeChange = (newDateRange) => {
    const [newStartDate, newEndDate] = newDateRange;
    if (newStartDate) {
      setStartDate(newStartDate);
    }
    if (newEndDate) {
      changeEndDate(newEndDate);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en-gb"}>
      <Stack spacing={2}>
        <DateRangePicker
          localeText={{ start: "search start date", end: "search end date" }}
          slotProps={{ textField: { size: "small" } }}
          value={[startdate, enddate]}
          onChange={handleDateRangeChange}
        />
      </Stack>
    </LocalizationProvider>
  );
}
