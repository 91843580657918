import { Fragment, useContext } from "react";
import Header from "../../components/section-headers/Header";

import {
  Box,
  Button,
  CssBaseline,
  ThemeProvider,
  Typography,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import SectionText from "../../components/section-headers/SectionText";
import AuthContext from "../../context/AuthContext";
import { ColorModeContext, tokens } from "../../theme";
import SiteMarkIconLegal from "./SiteMarkIconLegal";
const TermsAndConditions = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let { user } = useContext(AuthContext);

  return (
    <ColorModeContext.Provider value="dark">
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <Fragment>
          <Box m="20px" sx={{ pb: 5 }}>
            <Box px={{ xs: 1, sm: 2, md: 20, lg: 30, xl: 50 }}>
              {user ? <Fragment /> : <SiteMarkIconLegal />}
              <Header
                title="Terms & Conditions"
                subtitle="These Terms and Conditions govern your use of our website and services. 
            By accessing or using our website, you agree to be bound by these Terms."
              />
              <Box mb="10px">
                <Typography
                  variant="h6"
                  color={colors.greenAccent[400]}
                  fontWeight="bold"
                  sx={{ mb: "5px" }}
                >
                  1. Introduction
                </Typography>
                <Typography variant="h7" color={colors.grey[100]}>
                  Welcome to nemoutages! This tool was built to avoid needing to
                  download CSV files from
                </Typography>
                <Link
                  to="https://aemo.com.au/en/energy-systems/electricity/national-electricity-market-nem/data-nem/network-data/network-outage-schedule"
                  target="_blank"
                >
                  <Typography
                    variant="h7"
                    color={colors.greenAccent[400]}
                    p="0px 5px 0px 5px"
                  >
                    AEMO's website
                  </Typography>
                </Link>
                <Typography variant="h7" color={colors.grey[100]}>
                  or write complex SQL queries to view the latest version of the
                  Network Outage Schedule (NOS).
                </Typography>
              </Box>
              <Box mb="10px">
                <Typography variant="h7" color={colors.grey[100]}>
                  nemoutages goes one step further and allows users to directly
                  search for a particular generator or load (DUID) in the
                  National Electricity Market (NEM), and returns the linked
                  network outages and constraint sets that may impact that
                  particular DUID.
                </Typography>
              </Box>

              <Box mb="10px">
                <Typography variant="h7" color={colors.grey[100]}>
                  Create an account to view, query and search the complete
                  Network Outage Schedule. Account users can also search for all
                  possible constraints that can impact a specific DUID - not
                  just the ones in the latest version of the NOS.
                </Typography>
              </Box>

              <Box mb="10px">
                <Typography variant="h7" color={colors.grey[100]} mr="5px">
                  For any questions or suggested improvements, please contact us
                  at:
                </Typography>
                <Typography color={colors.greenAccent[400]} variant="h7">
                  admin@nemoutages.com.au
                </Typography>
              </Box>
              <Box mb="30px">
                <Typography
                  fontWeight="bold"
                  mr="5px"
                  variant="h7"
                  color={colors.grey[100]}
                >
                  NOTE:
                </Typography>
                <Typography variant="h7" color={colors.grey[100]}>
                  All times are in NEM Time, Australian Eastern Standard Time
                  (AEST)
                </Typography>
              </Box>

              <SectionText
                section="2. User Accounts"
                text="Account Creation: Users must register to access certain features of
            our website. You agree to provide accurate and complete information
            when creating an account. Account Responsibility: You are responsible
            for maintaining the confidentiality of your account and password and
            for restricting access to your computer. Account Termination: Users
            can delete their accounts at any time. We reserve the right to
            terminate your account if you violate these Terms."
              />
              <SectionText
                section="3. Use of Data and Third-Party Information"
                text="Data Processing: We collect and process open-source data from
            Australian Energy Market Operator (AEMO) to provide updated Network
            Outage Schedule (NOS) information. Data Update Frequency: Our service
            updates information every 30 minutes. However, some data, such as the
            SPDCONNECTIONPOINTCONSTRAINT & GENCONDATA tables, are updated only at a monthly frequency by AEMO. Disclaimer
            on Data Accuracy: While we strive to provide accurate and up-to-date
            information, we are not liable for any inaccuracies or outdated
            information in the data provided by third parties."
              />
              <SectionText
                section="4. Intellectual Property Rights"
                text="Ownership: All content on this website, including text, graphics,
            logos, and software, is the property of nemoutages or its
            content suppliers and protected by international copyright laws."
              />
              <SectionText
                section="5. Limitations of Liability"
                text="Service Use: We are not liable for any direct, indirect, incidental,
            special, or consequential damages resulting from the use or inability
            to use our services. Data Reliance: We are not liable for any
            decisions made based on the information provided through our service."
              />
              <SectionText
                section="6. User Conduct"
                text="Prohibited Activities: Users agree not to engage in activities that
            could harm the website or its users, or use the website for any
            unlawful purpose."
              />
              <SectionText
                section="7. Governing Law"
                text="Jurisdiction: These Terms and Conditions are governed by and construed in accordance with the laws of the state of Queensland, Australia. By using our services, users agree to submit to the exclusive jurisdiction of the courts located in Brisbane, Queensland, for the resolution of any disputes.

            Local Compliance: Our service is designed to be compliant with the laws and regulations of Queensland, Australia. This includes adherence to the Privacy Act 1988 (Cth) as it applies in Queensland, the Australian Consumer Law as it is enforced in Queensland, and other relevant Queensland state legislation.
            
            Regional Specificity: If any provision of these Terms and Conditions is found to be invalid or unenforceable under Queensland law, that provision will be superseded by a valid, enforceable provision that most closely matches the intent of the original provision, and the remainder of these Terms and Conditions will continue in effect.
            
            Dispute Resolution: Any disputes arising out of or related to the use of our services or these Terms will be resolved under the jurisdiction of the state of Queensland, specifically in Brisbane, and parties agree to seek resolution within this legal framework."
              />
              <SectionText
                section="8. Amendments to Terms"
                text="We reserve the right to modify these Terms at any time. Your continued
            use of the site after any changes indicates your acceptance of the new
            Terms."
              />
              <SectionText
                section="9. Privacy Policy"
                text="We are committed to respecting the privacy of our users and ensuring the safe and secure management of your data. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website and use our services."
              />
              <Box display="flex" justifyContent="start" mb="20px">
                <Button
                  component={Link}
                  to="/privacy-policy"
                  type="submit"
                  color="secondary"
                  variant="contained"
                >
                  Privacy Policy
                </Button>
              </Box>
              <Box mb="30px">
                <Typography
                  variant="h6"
                  color={colors.greenAccent[400]}
                  fontWeight="bold"
                  sx={{ mb: "5px" }}
                >
                  10. Contact Information
                </Typography>
                <Typography variant="h7" color={colors.grey[100]} mr="5px">
                  For any questions about these Terms, please contact us at:
                </Typography>
                <Typography
                  color={colors.greenAccent[400]}
                  variant="h7"
                  mb="20px"
                  fontWeight="bold"
                >
                  admin@nemoutages.com.au
                </Typography>
              </Box>
              <Typography color={colors.grey[100]} variant="h7" mr="5px">
                Last Updated:
              </Typography>
              <Typography
                color={colors.greenAccent[400]}
                variant="h7"
                mb="20px"
                fontWeight="bold"
              >
                2024/12/04
              </Typography>
            </Box>
          </Box>
        </Fragment>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default TermsAndConditions;
