import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Drawer, { drawerClasses } from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

import { Box } from "@mui/material";
import { Fragment, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../context/AuthContext";
import DataContext from "../../../context/DataContext";
import SettingsContext from "../../../context/SettingsContext";
import OptionsMenu from "../sidebar/OptionsMenu";
import SidebarMenu from "../sidebar/SidebarMenu";

function TruncatedEmail({ email, maxLength = 20 }) {
  // Find the position of '@' in the email
  const atIndex = email.indexOf("@");

  // Check if email length exceeds maxLength and has '@'
  const displayText =
    email.length > maxLength && atIndex > 0
      ? `${email.slice(0, atIndex)}`
      : email;

  return <span>{displayText}</span>;
}

function SideMenuMobile({ open, toggleDrawer }) {
  let { user, authTokens, logoutUser } = useContext(AuthContext);
  let { mode, setMode } = useContext(SettingsContext);
  let { menu, setMenu, setSectionMenu } = useContext(DataContext);

  let navigate = useNavigate();

  let callLogout = () => {
    setMode("dark");
    logoutUser();
    navigate("/");
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      sx={{
        [`& .${drawerClasses.paper}`]: {
          backgroundImage: "none",
          backgroundColor: "background.paper",
        },
      }}
    >
      <Stack
        sx={{
          maxWidth: "70dvw",
          height: "100%",
        }}
      >
        <Stack direction="row" sx={{ p: 2, pb: 0, gap: 1 }}>
          <Fragment>
            <Box sx={{ ml: "auto", textAlign: "end" }}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 500, lineHeight: "16px" }}
              >
                {user.name}
              </Typography>
              <Typography variant="caption" sx={{ color: "text.secondary" }}>
                <TruncatedEmail email={user.email} maxLength={26} />
              </Typography>
            </Box>
            <OptionsMenu
              component={
                <Avatar
                  sizes="small"
                  alt={user.name}
                  src="/static/images/avatar/6.jpg"
                  sx={{ width: 24, height: 24, color: "text.secondary" }}
                />
              }
            />
          </Fragment>
        </Stack>
        <Divider />
        <Stack sx={{ flexGrow: 1 }}>
          <SidebarMenu
            open={"true"}
            menu={menu}
            setMenu={setMenu}
            setSectionMenu={setSectionMenu}
          />
          <Divider />
        </Stack>

        <Stack sx={{ p: 2 }}>
          <Button
            variant="outlined"
            fullWidth
            onClick={callLogout}
            startIcon={<LogoutRoundedIcon />}
          >
            Logout
          </Button>
        </Stack>
      </Stack>
    </Drawer>
  );
}

SideMenuMobile.propTypes = {
  open: PropTypes.bool,
  toggleDrawer: PropTypes.func.isRequired,
};

export default SideMenuMobile;
